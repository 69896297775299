<template>
<dash-page-new
    title="Webinars will be here"
>

  <h1>Webinars Wonderfull is here : {{ $t('Webinar') }} </h1>
  <h1>This is additional test to check git capabilities for sub-submodule</h1>

  <ws-button @click="$store.dispatch('webinars/GET_WEBINARS')" label="webinar.add" />


</dash-page-new>
</template>

<script>
export default {
  name: "Webinars"
}
</script>

<style scoped>

</style>